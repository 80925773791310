// Helper methods and constants for Playwright

const idVocabulary = {
  activity: 'acty',
  activities: 'acty',
  admin: 'adm',
  administration: 'adm',
  application: 'appn',
  applications: 'appn',
  approver: 'appv',
  approval: 'appv',
  approvals: 'appv',
  button: 'btn',
  callToAction: 'cta',
  card: 'card',
  career: 'carr',
  carousel: 'crsl',
  checkbox: 'chbx',
  content: 'ctnt',
  contents: 'ctnt',
  container: 'ctnr',
  detail: 'dtl',
  details: 'dtl',
  dropdown: 'dpdn',
  filter: 'fltr',
  finance: 'fin',
  financial: 'fin',
  goal: 'goal',
  hosted: 'hstd',
  icon: 'icon',
  image: 'img',
  item: 'item',
  left: 'lt',
  lineItem: 'ln-item',
  linkFrom: 'link-from',
  linkTo: 'link-to',
  list: 'list',
  logo: 'logo',
  manager: 'mngr',
  menu: 'menu',
  next: 'nxt',
  payment: 'pymt',
  payments: 'pymt',
  playwright: 'pw',
  previous: 'prv',
  profile: 'prfl',
  program: 'prgm',
  programs: 'prgm',
  progress: 'pgrn',
  progression: 'prgn',
  requester: 'rqtr',
  requests: 'appn',
  right: 'rt',
  role: 'role',
  row: 'row',
  skill: 'skll',
  skills: 'skll',
  step: 'st',
  sponsor: 'spsr',
  submit: 'sbmt',
  submisson: 'sbmt',
  table: 'tbl',
  tag: 'tag',
  termsAndConditions: 'tc',
  text: 'txt',
  user: 'user',
  users: 'user',
};

const v = idVocabulary;
const pw = v.playwright;

const GENERATED_TEST_ID_CATEGORIES = {
  detailItemText: `${pw}-${v.detail}-${v.item}-${v.text}`,
};

const STATIC_TEST_IDS = {
  // These IDs are used in Playwright tests
  activitiesLink: `${pw}-${v.linkTo}-${v.activities}`,
  applicationTableRow: `${pw}-${v.application}-${v.table}-${v.row}`,
  approvalOverview: `${pw}-${v.approval}-overview`,
  approvalReason: `${pw}-${v.approval}-reason`,
  careerFilterTag: `${pw}-${v.career}-${v.filter}-${v.tag}`,
  careerSkillsList: `${pw}-${v.career}-${v.skills}-${v.list}`,
  nextProgramsButton: `${pw}-${v.next}-${v.programs}-${v.button}`,
  previousProgramsButton: `${pw}-${v.previous}-${v.programs}-${v.button}`,
  profileDropdownMenu: `${pw}-${v.profile}-${v.dropdown}-${v.menu}`,
  requestsLink: `${pw}-${v.linkTo}-${v.requests}`,
  termsAndConditionsText: `${pw}-${v.termsAndConditions}-${v.text}`,

  // These IDs are fixed on third-party pages
  hostedPaymentSubmitButton: 'hosted-payment-submit-button', // fixed on Stripe page

  // These IDs are defined but not used in any known Playwright tests
  linkFromLogo: `${pw}-${v.linkFrom}-${v.logo}`,
  profileImage: `${pw}-${v.profile}-${v.image}`,
};

const ICON_IDS = {
  progressBarCheckmark: 'd2l-tier1:check',
};

/**
 * Generates a Playwright data-testid value when a constant one can't be
 * used.
 *
 * @param {String} category - loose, arbitrary category of element being labelled
 * @param {String} label - distinct identifier for this element category on this page
 * @returns {String} - kebab-case test ID suitable for use with Playwright
 */
const makePlaywrightTestId = (category = '', label = '') => {
  if (!(Object.values(GENERATED_TEST_ID_CATEGORIES).includes(category))) {
    console.warn(`'${category}' is not a defined category value for generating Playwright test IDs. Using it, but consider using or defining a category in helpers/playwright.js.`);
  }
  return [category, label].join(' ')
    .trim()
    .replace(/\s+/g, '-')
    .toLowerCase()
    .replace(/[^-a-z0-9]/g, '');
};

export {
  GENERATED_TEST_ID_CATEGORIES,
  ICON_IDS,
  makePlaywrightTestId,
  STATIC_TEST_IDS
};
