import { dedupeMixin } from '@open-wc/dedupe-mixin';
import { navigator as nav } from 'lit-element-router';
import { nothing } from 'lit';

import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { NovaNavMixin } from '../nova-nav/nova-nav.js';

import { FULL_PERMISSIONS } from '../../../../shared/permissions.js';

export const PERMISSION_FAILURE_TYPES = {
  REDIRECT: 'redirect',
  HIDE: 'hide',
};

const COMPONENTS_TO_DISABLE = [
  'input',
  'select',
  'textarea',
  'd2l-tag-list-item',
  'd2l-tag-list',
  'd2l-input-date',
  'd2l-input-text',
  'd2l-input-textarea',
  'd2l-input-number',
  'd2l-input-checkbox',
  'd2l-button',
  'd2l-button-icon',
  'd2l-button-subtle',
  'd2l-button-icon',
  'd2l-button-subtle-icon',
  'd2l-selection-input',
  'd2l-switch',
  'nova-async-button',
  'nova-json-input',
  'nova-json-input-array',
  'nova-button',
  'nova-button-subtle',
  'nova-htmleditor',
];
const COMPONENTS_TO_NOT_SELECTABLE = [
  'd2l-list-item',
];

export const NovaPermissionMixin = dedupeMixin(superclass => class extends RequesterMixin(nav(NovaNavMixin(superclass))) {

  static get properties() {
    return {
      viewPermissions: { type: Object }, // Either a string or an array of strings
      updatePermissions: { type: Object }, // Either a string or an array of strings
      canView: { type: Boolean, reflect: true },
      canUpdate: { type: Boolean, reflect: true },
      handlePermissionFailure: { type: String }, // One of the permission failure types
    };
  }

  constructor() {
    super();
    this.canView = true;
    this.canUpdate = true;
    this._requiredViewPermissions = [];
    this._requiredUpdatePermissions = [];
    this.handlePermissionFailure = PERMISSION_FAILURE_TYPES.HIDE; // Default behavior is to hide
  }

  willUpdate(changedProperties) {
    super.willUpdate(changedProperties);
    if (changedProperties.has('viewPermissions')) {
      this._normalizePermissions(this.viewPermissions, '_requiredViewPermissions');
    }
    if (changedProperties.has('updatePermissions')) {
      this._normalizePermissions(this.updatePermissions, '_requiredUpdatePermissions');
    }
  }

  _normalizePermissions(value, propertyName) {
    if (typeof value === 'string') {
      this[propertyName] = [value];
    } else if (Array.isArray(value)) {
      this[propertyName] = value;
    } else {
      console.warn(`Invalid ${propertyName} value, must be a string or an array`);
      this[propertyName] = [];
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
  }

  get _userPermissions() {
    const adminRolesEnabled = this.session.tenant.hasTag('adminRoles');
    if (!adminRolesEnabled) return Object.keys(FULL_PERMISSIONS);
    return this.session.user.permissions;
  }

  _hasViewPermission() {
    // If user has update permissions, they can view as well
    if (this.canUpdate) return true;

    if (!this._requiredViewPermissions || this._requiredViewPermissions.length === 0) {
      return true;
    }

    // Check if the user has ALL of the required view permissions
    return this._requiredViewPermissions.every(permission =>
      this._userPermissions.includes(permission)
    );
  }

  _hasUpdatePermission() {
    if (!this._requiredUpdatePermissions || this._requiredUpdatePermissions.length === 0) {
      return false;
    }

    // Check if the user has ALL of the required update permissions
    return this._requiredUpdatePermissions.every(permission =>
      this._userPermissions.includes(permission)
    );
  }

  updated(changedProperties) {
    if (changedProperties.has('_userPermissions') || changedProperties.has('viewPermissions') || changedProperties.has('updatePermissions')) {
      this.canUpdate = this._hasUpdatePermission();
      this.canView = this.canUpdate || this._hasViewPermission();

      if (!this.canView) {
        this._handlePermissionFailure();
      }
      if (!this.canUpdate) {
        this._handleUpdateFailure();
      }
    }
    super.updated(changedProperties);
  }

  _handleUpdateFailure() {
    this.updateComplete.then(() => {
      for (const component of COMPONENTS_TO_DISABLE) {
        const elements = this.shadowRoot.querySelectorAll(component);
        elements.forEach(element => {
          element.disabled = true;
          this.requestUpdate();
        });
      }
      for (const component of COMPONENTS_TO_NOT_SELECTABLE) {
        const elements = this.shadowRoot.querySelectorAll(component);
        elements.forEach(element => {
          element.selectable = false;
          this.requestUpdate();
        });
      }
    });
  }

  _handlePermissionFailure() {
    if (this.handlePermissionFailure === PERMISSION_FAILURE_TYPES.REDIRECT) {
      if (!this.canView) {
        this._redirectTo403();
      }
    }
  }

  _redirectTo403() {
    this.navigateWithoutHistory('/403');
  }

  notAllowedRender() {
    return nothing;
  }

  allowedRender() {
    return nothing;
  }

  render() {
    if (!this.canView && this.handlePermissionFailure === PERMISSION_FAILURE_TYPES.HIDE) {
      return this.notAllowedRender();
    }
    return this.allowedRender();
  }
});
