import { html } from 'lit';

import { formatIsoDate } from '../../../../../shared/helpers/dateTime.js';

const templates = {
  approveRequestConfirmation: (data, localize) => {
    const { activityCost, activityTitle, activityType, username, activityCostTooltipText, approvedAmountData, learnerTerminology } = data;

    const activityCostOpts = {};
    if (activityCostTooltipText !== undefined) {
      activityCostOpts.tooltipText = activityCostTooltipText;
    }
    const content = [
      { key: `general.${learnerTerminology}`, value: username },
      { key: 'confirmation-dialog.content.cost', value: activityCost, opts: activityCostOpts },
      { key: activityType, value: activityTitle },
    ];
    // add approved amount in confirmation box for hybrid case by case
    if (approvedAmountData.isHybridCaseByCase) {
      content.splice(2, 0, { key: 'confirmation-dialog.content.approvedAmount', value: approvedAmountData.approvedAmount });
    }
    return {
      title: 'confirmation-dialog.approveRequest.title',
      description: localize('confirmation-dialog.approveRequest.description'),
      content,
      button: {
        text: 'confirmation-dialog.approveRequest.button',
        action: 'approve',
      },
    };
  },
  cancelledStatusConfirmation: (data, localize) => {
    const { activityTitle, activityType, completionDate, completionStatus, refundPct, username, learnerTerminology } = data;
    const formattedCompletionDate = formatIsoDate(completionDate);
    const localizedDescription = html`${localize('confirmation-dialog.cancelledStatus.description.1')} <b>${activityTitle}</b> ${localize('confirmation-dialog.cancelledStatus.description.2', { formattedCompletionDate, username, refundPct })}`;
    const localizedCompletionStatus = localize(`confirmation-dialog.status.${completionStatus.toLowerCase()}`);
    return {
      title: 'confirmation-dialog.completionStatus.title',
      description: localizedDescription,
      content: [
        { key: 'confirmation-dialog.content.status', value: html`<b>${localizedCompletionStatus}</b>` },
        { key: 'confirmation-dialog.content.refundPct', value: html`<b>${refundPct}%</b>` },
        { key: activityType, value: activityTitle },
        { key: `general.${learnerTerminology}`, value: username },
        { key: 'confirmation-dialog.content.date', value: formattedCompletionDate },
      ],
      footerDescription: `confirmation-dialog.cancelledOrWithdrawn.${learnerTerminology}.footerDescription`,
      actionMessage: 'confirmation-dialog.actionMessage.cantBeUndone',
    };
  },
  declineRequestConfirmation: (data, localize) => {
    const { activityCost, activityTitle, activityType, username, learnerTerminology } = data;
    return {
      title: 'confirmation-dialog.declineRequest.title',
      description: localize('confirmation-dialog.declineRequest.description'),
      content: [
        { key: `general.${learnerTerminology}`, value: username },
        { key: 'confirmation-dialog.content.cost', value: activityCost },
        { key: activityType, value: activityTitle },
      ],
      button: {
        text: 'confirmation-dialog.declineRequest.button',
        action: 'decline',
      },
    };
  },
  enrollmentDateConfirmation: (data, localize) => {
    const { activityTitle, activityType, completionStatus, enrollDate, username, learnerTerminology } = data;
    const formattedEnrollDate = formatIsoDate(enrollDate);
    const localizedDescription = html`${localize('confirmation-dialog.enrollmentDate.description', { username, formattedEnrollDate, activityTitle, bold: (...chunks) => html`<b>${chunks}</b>` })}`;
    const localizedCompletionStatus = localize(`confirmation-dialog.status.${completionStatus.toLowerCase()}`);
    return {
      title: 'confirmation-dialog.enrollmentDate.title',
      description: localizedDescription,
      content: [
        { key: 'confirmation-dialog.content.status', value: html`<b>${localizedCompletionStatus}</b>` },
        { key: activityType, value: activityTitle },
        { key: `general.${learnerTerminology}`, value: username },
        { key: 'confirmation-dialog.content.date', value: formattedEnrollDate },
      ],
      actionMessage: 'confirmation-dialog.actionMessage.cantBeUndone',
      doNotShowDialog: 'disableEnrollmentDateConfirmation',
    };
  },
  failedStatusConfirmation: (data, localize) => {
    const { activityTitle, activityType, completionDate, completionStatus, username, learnerTerminology } = data;
    const formattedCompletionDate = formatIsoDate(completionDate);
    const localizedDescription = html`${localize('confirmation-dialog.failedStatus.description', { username, formattedCompletionDate, activityTitle, bold: (...chunks) => html`<b>${chunks}</b>` })}`;
    const localizedCompletionStatus = localize(`confirmation-dialog.status.${completionStatus.toLowerCase()}`);
    return {
      title: 'confirmation-dialog.completionStatus.title',
      description: localizedDescription,
      content: [
        { key: 'confirmation-dialog.content.status', value: html`<b>${localizedCompletionStatus}</b>` },
        { key: activityType, value: activityTitle },
        { key: `general.${learnerTerminology}`, value: username },
        { key: 'confirmation-dialog.content.date', value: formattedCompletionDate },
      ],
      footerDescription: `confirmation-dialog.failedStatus.${learnerTerminology}.footerDescription`,
      actionMessage: 'confirmation-dialog.actionMessage.cantBeUndone',
      doNotShowDialog: 'disableFailedStatusConfirmation',
    };
  },
  passedStatusConfirmation: (data, localize) => {
    const { activityTitle, activityType, completionDate, completionStatus, username, learnerTerminology } = data;
    const formattedCompletionDate = formatIsoDate(completionDate);
    const localizedDescription = html`${localize('confirmation-dialog.passedStatus.description.1', { username, formattedCompletionDate })} <b>${activityTitle}</b>, ${localize('confirmation-dialog.passedStatus.description.2', { username, formattedCompletionDate })}`;
    const localizedCompletionStatus = localize(`confirmation-dialog.status.${completionStatus.toLowerCase()}`);
    return {
      title: 'confirmation-dialog.completionStatus.title',
      description: localizedDescription,
      content: [
        { key: 'confirmation-dialog.content.status', value: html`<b>${localizedCompletionStatus}</b>` },
        { key: activityType, value: activityTitle },
        { key: `general.${learnerTerminology}`, value: username },
        { key: 'confirmation-dialog.content.date', value: formattedCompletionDate },
      ],
      footerDescription: `confirmation-dialog.passedStatus.${learnerTerminology}.footerDescription`,
      actionMessage: 'confirmation-dialog.actionMessage.cantBeUndone',
      doNotShowDialog: 'disablePassedStatusConfirmation',
    };
  },
  revokeRequestConfirmation: (data, localize) => {
    const { activityTitle, activityType, requestedApprovers } = data;
    const lastApprover = requestedApprovers.slice(-1);
    const formattedApprovers = requestedApprovers.length > 1 ? [requestedApprovers.slice(0, -1).join(', '), lastApprover].join(` ${localize('confirmation-dialog.revoke.approver-joining')} `) : requestedApprovers[0];
    return {
      title: 'confirmation-dialog.revoke.title',
      content: [
        { key: activityType, value: activityTitle },
        { key: 'confirmation-dialog.revoke.approvers', value: formattedApprovers },
      ],
      postContentMessage: 'confirmation-dialog.revoke.postContentMessage',
      button: {
        text: 'confirmation-dialog.revoke.confirmButton',
        action: 'revoke',
      },
      cancelButton: {
        text: 'confirmation-dialog.revoke.cancelButton',
      },
    };
  },
  withdrawnStatusConfirmation: (data, localize) => {
    const { activityTitle, activityType, completionDate, completionStatus, refundPct, username, learnerTerminology } = data;
    const formattedCompletionDate = formatIsoDate(completionDate);
    const localizedDescription = html`${localize('confirmation-dialog.withdrawnStatus.description.1', { username, formattedCompletionDate, refundPct })} <b>${activityTitle}</b>, ${localize('confirmation-dialog.withdrawnStatus.description.2', { username, formattedCompletionDate, refundPct })}`;
    const localizedCompletionStatus = localize(`confirmation-dialog.status.${completionStatus.toLowerCase()}`);
    return {
      title: 'confirmation-dialog.completionStatus.title',
      description: localizedDescription,
      content: [
        { key: 'confirmation-dialog.content.status', value: html`<b>${localizedCompletionStatus}</b>` },
        { key: 'confirmation-dialog.content.refundPct', value: html`<b>${refundPct}%</b>` },
        { key: activityType, value: activityTitle },
        { key: `general.${learnerTerminology}`, value: username },
        { key: 'confirmation-dialog.content.date', value: formattedCompletionDate },
      ],
      footerDescription: `confirmation-dialog.cancelledOrWithdrawn.${learnerTerminology}.footerDescription`,
      actionMessage: 'confirmation-dialog.actionMessage.cantBeUndone',
      doNotShowDialog: 'disableWithdrawnStatusConfirmation',
    };
  },

  toggleUserActiveStatusConfirmation: (data, localize) => {
    const bold = (...chunks) => html`<b>${chunks}</b>`;
    const ifTrue = (condition, value1, value2) => (condition ? value1 : value2);

    const admin = data;
    const fullName = `${admin?.firstName} ${admin?.lastName}`;
    const localizedDescription = ifTrue(admin?.active, html`<p>${localize('confirmation-dialog.deactivateUser.description', { fullName, bold })}</p>`, html`<p>${localize('confirmation-dialog.activateUser.description', { fullName, bold })}</p>`);
    const localizedTitle = ifTrue(admin?.active, 'confirmation-dialog.deactivateUser.title', 'confirmation-dialog.activateUser.title');
    const localizedButtonText = ifTrue(admin?.active, 'confirmation-dialog.deactivateUser.confirmButton', 'confirmation-dialog.activateUser.confirmButton');
    return {
      title: localizedTitle,
      description: localizedDescription,
      button: {
        text: localizedButtonText,
        action: 'toggleActiveStatus',
      },
      cancelButton: {
        text: 'general.button-text.cancel',
      },
    };
  },

  removeRoleConfirmation: (data, localize) => {
    const bold = (...chunks) => html`<b>${chunks}</b>`;
    const { roleName, usersWithRole, usersWithNoRoleAfterDelete } = data;
    let localizedDescription = html`<p>${localize('confirmation-dialog.removeRole.description.unassigned', { roleName, bold })}</p>`;
    if (usersWithRole !== 0) {
      if (usersWithNoRoleAfterDelete === 0) {
        localizedDescription = html`<p>${localize('confirmation-dialog.removeRole.description.assigned', {
          roleName,
          usersWithRole,
          bold,
        })}</p>`;
      } else {
        localizedDescription = html`<p>${localize('confirmation-dialog.removeRole.description.assigned-and-orphaned', {
          roleName,
          usersWithRole,
          usersWithNoRoleAfterDelete,
          bold,
        })}</p>`;
      }
    }
    return {
      title: 'confirmation-dialog.removeRole.title',
      description: localizedDescription,
      button: {
        text: 'confirmation-dialog.removeRole.button-text.confirm',
      },
    };
  },
};

export function getTemplateData(type, data, localize) {
  return templates[type](data, localize);
}
